import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import '../../App.css';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

function ListApprenant({ 
    classe,
    handleClickOpen,
    setSelectedStudent,
    toggleView,
    view
}) {
    // Vérifiez si 'classe' est un tableau
    const students = Array.isArray(classe) ? classe : [];

    const selection = (id) => {
        const choosenOne = students.find((element) => element.id === id);
        setSelectedStudent(choosenOne);
        handleClickOpen();
    };

    return (
        <Grid lg={8} sx={{ pt: '24px', pl: '24px' }}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 520
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h2 className="boldtitle">Apprenants</h2>
                    <IconButton
                        variant="contained"
                        color="primary"
                        onClick={toggleView}
                    >
                        {view ? <PersonAddAlt1Icon /> : <CloseIcon />}
                    </IconButton>
                </div>
                {students.length > 0 ? (
                    <TableContainer sx={{ maxHeight: 440, paddingLeft: 0, width: '100%' }}>
                        <List sx={{ width: '100%', padding: 0 }}>
                            {students.map((student) => {
                                // Calculs spécifiques à chaque étudiant
                                const competences = student.competences;
                                const totalNiveau = competences.reduce((acc, competence) => acc + competence.niveau, 0);
                                const achieved = Math.floor((totalNiveau / (competences.length * 3)) * 100);

                                const briefsWithStatutZero = student.briefs
                                    .filter(brief => brief.statut === 0)
                                    .length;

                                const lien = `${student.photo}`;

                                return (
                                    <div key={student.id}>
                                        <ListItemButton onClick={() => selection(student.id)}>
                                            <div className='row1'>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div style={{ display: 'flex', padding: '15px 0', alignItems: 'center' }}>
                                                        <div className='datebrief'>
                                                            <Avatar alt={student.prenom} src={lien} />
                                                        </div>
                                                        <div style={{ border: "1px solid transparent" }} className='marge'>
                                                            <span style={{ fontSize: '18px', color: 'white', fontWeight: '600', whiteSpace: "nowrap" }}>
                                                                {student.prenom} {student.nom}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', width: '185px', justifyContent: 'space-between', height: 'fit-content' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className='circleiconinfos'>
                                                            <SentimentVerySatisfiedIcon sx={{ height: '15px', color: '#1E1E1E' }} />
                                                        </div>
                                                        <span style={{ fontSize: '20px', color: 'white' }}>{student.discipline}</span>
                                                    </div>
                                                    <Divider orientation="vertical" variant="middle" flexItem />
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className='circleiconinfos'>
                                                            <ClassOutlinedIcon sx={{ height: '15px', color: '#1E1E1E' }} />
                                                        </div>
                                                        <span style={{ fontSize: '20px', color: 'white' }}>{briefsWithStatutZero}</span>
                                                    </div>
                                                    <Divider orientation="vertical" variant="middle" flexItem />
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className='circleiconinfos'>
                                                            <ChecklistIcon sx={{ height: '15px', color: '#1E1E1E' }} />
                                                        </div>
                                                        <span style={{ fontSize: '20px', color: 'white', width: '45px' }}>{achieved}%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ListItemButton>
                                        <Divider />
                                    </div>
                                );
                            })}
                        </List>
                    </TableContainer>
                ) : (
                    <div style={{ color: 'white', textAlign: 'center', marginTop: '20px' }}>
                        Il n'y a pas d'apprenant actuellement.
                    </div>
                )}
            </Paper>
        </Grid>
    );
}

export default ListApprenant;
