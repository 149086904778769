import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import axios from "axios";
import Activity from "./Activity";

const parseDate = (dateStr) => {
  if (!dateStr || typeof dateStr !== "string") {
    console.error("Date invalide : ", dateStr);
    return null; // Ou une valeur par défaut, par exemple : new Date(0)
  }
  
  const [day, month, year] = dateStr.split("-").map(Number);
  
  // Vérifiez que tous les composants de la date sont valides
  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    console.error("Composants de la date non valides : ", { day, month, year });
    return null; // Ou une autre valeur par défaut
  }

  return new Date(year, month - 1, day);
};


const Activities = ({ classe, filteredUsers }) => {
  const [briefs, setBriefs] = useState([]);
  const [allBriefs, setAllBriefs] = useState([]);
  const [allResponses, setAllResponses] = useState([]);
  const [numberOfBriefs, setNumberOfBriefs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [titre, setTitre] = useState("");
  const [referentiel, setReferentiel] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const today = new Date();

  // Fetch data from API
  const fetchData = async () => {
    const promo = filteredUsers[0]?.promo;
    let localReferentiel = "";
  
    if (filteredUsers[0]?.referentiel === "Développeur web") {
      localReferentiel = "dev";
    } else if (filteredUsers[0]?.referentiel === "Référent digital") {
      localReferentiel = "ref";
    } else if (filteredUsers[0]?.referentiel === "Digital creator") {
      localReferentiel = "crea";
    }
  
    try {
      const [uniqueBriefsRes, allBriefsRes, responsesRes] = await Promise.all([
        axios.get(`https://backend-codeschool241.onrender.com/briefs/${localReferentiel}/${promo}`),
        axios.get(`https://backend-codeschool241.onrender.com/allbriefs/${localReferentiel}/${promo}`),
        axios.get(`https://backend-codeschool241.onrender.com/reponses`)
      ]);
  
      const filteredBriefs = uniqueBriefsRes.data.filter((brief) => brief.promo === promo);
      const matchingBriefs = allBriefsRes.data.filter((module) => module.promo === promo);
  
      setBriefs(uniqueBriefsRes.data);
      setAllBriefs(allBriefsRes.data);
      setAllResponses(responsesRes.data);
      setNumberOfBriefs(matchingBriefs);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    }
  };
  


  useEffect(() => {

    
    fetchData();
    
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Grid container spacing={2} sx={{ pt: 3, pl: 3 }}>
        <Grid item lg={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <h2 className="boldtitle">Liste des activités</h2>
            <TableContainer sx={{ maxHeight: 440 }}>
              <List>
                {briefs.map((brief, index) => {
                  if (!brief?.titre) {
                    console.warn("Brief sans titre :", brief);
                    return null;
                  }

                  const relatedBriefs = Array.isArray(allBriefs)
                    ? allBriefs.filter((module) => module.titre === brief.titre)
                    : [];
                  const relatedResponses = Array.isArray(allResponses)
                    ? allResponses.filter((module) => module.id_brief === relatedBriefs.id)
                    : [];
                    const triBriefs = () => {
                      console.log(relatedBriefs)
                    }

                  return (
                    <div
                      key={index}
                      onClick={() => triBriefs()} // Gestionnaire d'événement
                      style={{ cursor: "pointer" }} // Indique que l'élément est cliquable
                    >
                      <Activity
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        titre={titre}
                        setTitre={setTitre}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        setMessage={setMessage}
                        message={message}
                        handleClickOpen={() => setOpen(true)}
                        setOpen={setOpen}
                        open={open}
                        brief={relatedBriefs[0]}
                        number={relatedBriefs}
                        relatedBriefs={relatedBriefs}
                        parseDate={parseDate}
                        today={today}
                        classe={classe}
                        allBriefs={allBriefs}
                        briefs={numberOfBriefs}
                        allResponses={allResponses}
                      />
                      <Divider />
                    </div>
                  );
                })}
              </List>

            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Activities;
