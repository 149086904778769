import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import '../../App.css';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TransferList from './TransferList';
import NewBriefCheckout from './NewBriefCheckout';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function AddActivity({ classe, filteredUsers }) {
  const [file, setFile] = useState(null);

  const [formData, setFormData] = useState({
    titre: '',
    message: '',
    date: null,
    file: file,
  });

  const [left, setLeft] = useState(classe);
  const [right, setRight] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [arraymodules, setarraymodules] = useState([]);
  const [modules, setmodules] = useState();
  const [isSelectEnabled, setIsSelectEnabled] = useState(false);


  const handleChangeModules = (event) => {
    const getObjectifs = async () => {
      const response2 = await axios.get('https://backend-codeschool241.onrender.com/objectifs');
      const data2 = response2.data.filter(module => module.id_module === event.target.value)
      setmodules(event.target.value)
      setarrayobjectifs(data2)
      setIsSelectEnabled(true)
    }
    getObjectifs()
  };

  const [arrayobjectifs, setarrayobjectifs] = useState([]);
  const [objectifs, setobjectifs] = useState('');
  const [fichier, setfichier] = useState('');

  const handleChangeObjectifs = (event) => {
    setobjectifs(event.target.value);
    
  };



  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      message: value,
    }));
  };

  const handleChangeTitre = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      titre: value,
    }));
  };

  const handleDateChange = (newDate) => {
    setFormData((prevData) => ({
      ...prevData,
      date: newDate,
    }));
  };


  

  const getData = async () => {
    const response = await axios.get('https://backend-codeschool241.onrender.com/modules');
    const data = response.data.filter(module => module.cohorte === classe[0].referentiel)
    setarraymodules(data)
    console.log(data)

    

  }

  React.useEffect(() => { 
    getData();
    }, []); 


  const [progress, setProgress] = useState(0);
  const [fileName, setfileName] = useState("");

  const handleFileChangeFile = (e) => {
    setFile(e.target.files[0]);
    setfileName(e.target.files[0].name);
  };

  const handleUpload = () => {
    if (!file) return;

    const storageRef = ref(storage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          setfichier(downloadURL);
        });
      }
    );
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === 1) {
      handleUpload()
    }
  };


  const goLiive = () => {
    
        setIsLoading(true);

        const brief = {
          titre: formData.titre,
          date: `${formData.date.$D}-${formData.date.$M + 1}-${formData.date.$y}`,
          source: `${fichier}`,
          statut: 0,
          description: formData.message,
          id_etudiant: '',  // Vous pouvez ajouter l'id de l'étudiant si nécessaire
          cohorte: classe[0].referentiel,      // Vous pouvez ajouter la cohorte si nécessaire
          id_objectif: objectifs,
          promo: filteredUsers[0].promo  // Vous pouvez ajouter l'id de l'objectif si nécessaire
        };        

        const promises = right.map((element) => {
          const data = { ...brief, id_etudiant: element.id };
          return axios.post('https://backend-codeschool241.onrender.com/briefs', data);
        });

        Promise.all(promises)
          .then((responses) => {
            console.log('Données envoyées avec succès:', responses);
            setIsSuccess(true);
          })
          .catch((error) => {
            console.error("Erreur lors de l'envoi des données:", error);
            setIsSuccess(false);
          })
          .finally(() => {
            setTimeout(() => {
              setActiveStep(0);
              setIsLoading(false);
              setOpenSnackbar(true);
              setFormData({
                titre: '',
                message: '',
                date: null,
                file: null,
              });
              // Rafraîchir la page
              window.location.reload();

            }, 2000);
          });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  function getStepContent(activeStep) {
    switch (activeStep) {
      case 0:
        return (
          <div className='case0'>
            <h2 className='boldtitle' style={{ marginTop: '0' }}>Détails de l'activité</h2>
            <TextField
              id="outlined-multiline-static"
              label="Titre"
              multiline
              rows={1}
              name="titre"
              value={formData.titre}
              onChange={handleChangeTitre}
              sx={{ width: '100%', marginBottom: '15px' }}
            />
            <TextField
              id="outlined-multiline-static"
              label="Description"
              multiline
              rows={6}
              name="message"
              value={formData.message}
              onChange={handleChange}
              sx={{ width: '100%', marginBottom: '7px' }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker
                  label="Date de livraison"
                  value={formData.date}
                  onChange={handleDateChange}
                  sx={{ width: '100%' }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <Box sx={{ minWidth: 120, mt:'15px' }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Modules</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={modules}
                  label="Modules"
                  onChange={handleChangeModules}
                >
                  {arraymodules.map((module) => (
                    <MenuItem value={module.id_module} key={module.id_module}>{module.module}</MenuItem>
                  ))}                  

                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: 120, mt:'15px' }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Objectifs</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={objectifs}
                  label="Objectifs"
                  disabled={!isSelectEnabled}
                  onChange={handleChangeObjectifs}
                >
                  {arrayobjectifs.map((objectif) => (
                    <MenuItem value={objectif.id_objectif} key={objectif.id_objectif}>{objectif.objectif}</MenuItem>
                  ))}   
                </Select>
              </FormControl>
            </Box>

            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              sx={{ width: '100%', marginTop: '15px' }}
            >
              Ajouter un fichier
              <VisuallyHiddenInput type="file" onChange={handleFileChangeFile} />
            </Button>
            <div style={{ height: '30px', display: 'flex', justifyContent: 'center' }}>
              <span style={{ textAlign: 'center', width: '100%', fontSize: '18px' }}>
                {file ? file.name : 'Aucun fichier sélectionné'}
              </span>
            </div>
          </div>
        );

      case 1:
        return (
          <div>
            <h2 className="boldtitle" style={{ marginTop: '0' }}>Destinataire(s)</h2>
            <TransferList
              classe={classe}
              setLeft={setLeft}
              setRight={setRight}
              left={left}
              right={right}
            />
          </div>
        );

      case 2:
        return (
          <NewBriefCheckout
            right={right}
            datas={[right, formData]}
            fichier={fileName}
          />
        );

      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <Grid item xs={12} md={4} lg={12}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: 670,
          justifyContent: 'space-between',
        }}
      >
        {getStepContent(activeStep)}
        <div style={{ height: '50px' }}>
          <MobileStepper
            variant="dots"
            steps={3}
            position="static"
            activeStep={activeStep}
            sx={{ maxWidth: '100%', flexGrow: 1, height: 'fit-content' }}
            nextButton={
              <div>
                {activeStep === 2 ? (
                  <Button
                    variant="contained"
                    endIcon={isLoading ? <CircularProgress size={24} color="inherit" /> : <SendIcon />}
                    onClick={goLiive}
                    disabled={isLoading}
                  >
                    Envoyer
                  </Button>
                ) : (
                  <Button size="small" onClick={handleNext} disabled={activeStep === 2}>
                    Suivant
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                  </Button>
                )}
              </div>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                Retour
              </Button>
            }
          />
        </div>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <MuiAlert onClose={handleSnackbarClose} severity={isSuccess ? 'success' : 'error'}>
            {isSuccess ? 'Brief ajouté avec succès !' : 'Erreur lors de l\'ajout du brief.'}
          </MuiAlert>
        </Snackbar>
      </Paper>
    </Grid>
  );
}

export default AddActivity;
