import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DownloadIcon from '@mui/icons-material/Download';
import TextField from '@mui/material/TextField';
import LinkIcon from '@mui/icons-material/Link';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import InputAdornment from '@mui/material/InputAdornment';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import '../../App.css';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function Activite({ handleClose, open, competence, filteredUsers }) {
  const [message, setMessage] = useState('');
  const [link, setLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [adminMail, setAdminMail] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (filteredUsers && filteredUsers.length > 0) {
      checkAdmin();
    }
  }, [filteredUsers]);

  const checkAdmin = async () => {
    try {
      const response = await axios.get('https://backend-codeschool241.onrender.com/admins');
      const matchingAdmin = response.data.find(
        (admin) =>
          admin.promo === filteredUsers[0]?.promo &&
          admin.referentiel === filteredUsers[0]?.referentiel
      );

      if (matchingAdmin) {
        setAdminMail(matchingAdmin.email);
      } else {
        setError('Aucun administrateur correspondant trouvé.');
      }
    } catch (err) {
      console.error('Erreur lors de la récupération des administrateurs:', err);
      setError('Impossible de récupérer les données administratives.');
    }
  };

  const handleSend = async () => {
    if (!link || !message) {
      setError('Veuillez remplir tous les champs avant d\'envoyer.');
      return;
    }

    if (!filteredUsers || filteredUsers.length === 0) {
      setError('Aucun utilisateur trouvé pour envoyer le message.');
      return;
    }

    const idEtudiant = filteredUsers[0].id;
    const idBrief = competence.id;

    setIsLoading(true);

    try {
      // 1. Envoyer l'email
      await axios.post('https://backend-codeschool241.onrender.com/send-email', {
        to: adminMail,
        subject: `${filteredUsers[0].prenom} ${filteredUsers[0].nom} - Réponse à l'évaluation: ${competence.titre}`,
        text: message,
        link,
        competence,
      });

      // 2. Mettre à jour le statut du brief
      await axios.put(`https://backend-codeschool241.onrender.com/brief/${idBrief}`, { statut: 1 });

      // 3. Enregistrer la réponse
      await axios.post('https://backend-codeschool241.onrender.com/briefs-reponses', {
        id_etudiant: idEtudiant,
        id_brief: idBrief,
        message,
        lien: link,
      });

      alert('Message envoyé et réponse enregistrée avec succès.');
      handleClose();
    } catch (err) {
      // console.error('Erreur lors de l\'envoi ou de l\'enregistrement:', err);
      // setError('Une erreur est survenue lors de l\'envoi ou de l\'enregistrement.');
      alert('Erreur lors de l\'envoi.');
      handleClose();

    } finally {
      setIsLoading(false);
      window.location.reload()
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {competence.titre}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className="layout-brief" style={{ padding: '0 63px', display: 'flex' }}>
        <div className="brief-description">
          <h3>Description:</h3>
          <p>{competence.description}</p>
          <h3>Pièce jointe:</h3>
          <Button variant="outlined" startIcon={<DownloadIcon />} href={competence.source} download>
            Télécharger le fichier
          </Button>
          <h3>Date de livraison:</h3>
          <p>{competence.date}</p>
        </div>
        {(competence.statut === 0 || competence.statut === 3) && (
  <div className="response brief-description" style={{ marginTop: '35px' }}>
    {error && <Alert severity="error" sx={{ marginBottom: '20px' }}>{error}</Alert>}
    <TextField
      id="outlined-multiline-static"
      label="Message"
      multiline
      rows={7}
      value={message}
      onChange={(e) => setMessage(e.target.value)}
      sx={{ width: '100%' }}
    />
    <TextField
      id="input-with-icon-textfield"
      label="Lien"
      value={link}
      onChange={(e) => setLink(e.target.value)}
      sx={{ margin: '30px 0', width: '100%' }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LinkIcon />
          </InputAdornment>
        ),
      }}
      variant="outlined"
    />
    <Button
      variant="contained"
      endIcon={isLoading ? <CircularProgress size={20} /> : <SendIcon />}
      sx={{ width: '100%' }}
      onClick={handleSend}
      disabled={isLoading || !link || !message}
    >
      {isLoading ? 'Envoi en cours...' : 'Envoyer'}
    </Button>
  </div>
)}

      </div>
    </Dialog>
  );
}
