import React from 'react';
import '../../App.css'
import ChecklistIcon from '@mui/icons-material/Checklist';
import Divider from '@mui/material/Divider';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import axios from 'axios';



function InfosCohorte({ classe, briefs }) {
    if (!classe || classe.length === 0) {
        // Gestion du cas où classe est vide ou undefined
        return <div>Les informations de la cohorte ne sont pas disponibles.</div>;
    }

    var array = classe[0].competences
    const totalNiveau = classe[0].competences.reduce((acc, competence) => {
        return acc + competence.niveau;
      }, 0);
    const achieved= Math.floor((totalNiveau/(array.length*3))*100)

    const briefsWithStatutZero = classe[0].briefs
    .filter(competence => competence.statut === 0)
    .length;

    const filtered = briefs.filter(brief => brief.id_etudiant === classe[0].id);


    const activitiesWithStatutZero = filtered.filter(competence => competence.statut === 0).length;
    return (
        <div className='infoscohorte'>
            <h2>{classe[0].prenom} {classe[0].nom} <span style={{fontWeight:'500', fontSize:'16px', color:'white'}}>({classe[0].specialisation})</span></h2>
            <div style={{ display: 'flex', width:'230px', justifyContent:'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center'}}>
                    <div className='circleiconinfos'>
                        <SentimentVerySatisfiedIcon sx={{ height: '15px', color: '#1E1E1E' }} />
                    </div>
                    <span style={{ fontSize: '20px', color: 'white' }}>{classe[0].discipline}</span>
                </div>
                <Divider orientation="vertical" variant="middle" flexItem />
                <div style={{ display: 'flex', alignItems: 'center'}}>
                    <div className='circleiconinfos'>
                        <HistoryEduIcon sx={{ height: '15px', color: '#1E1E1E' }} />
                    </div>
                    <span style={{ fontSize: '20px', color: 'white' }}>{activitiesWithStatutZero}</span>
                </div>
                <Divider orientation="vertical" variant="middle" flexItem />
                <div style={{ display: 'flex', alignItems: 'center'}}>
                    <div className='circleiconinfos'>
                        <ClassOutlinedIcon sx={{ height: '15px', color: '#1E1E1E' }} />
                    </div>
                    <span style={{ fontSize: '20px', color: 'white' }}>{briefsWithStatutZero}</span>
                </div>
                <Divider orientation="vertical" variant="middle" flexItem />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='circleiconinfos'>
                        <ChecklistIcon sx={{ height: '15px', color: '#1E1E1E' }} />
                    </div>
                    <span style={{ fontSize: '20px', color: 'white', width:'45px' }}>{achieved}%</span>
                </div>
            </div>
        </div>
    );
}

export default InfosCohorte;
