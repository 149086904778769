import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import '../../App.css';
import ListApprenant from './ListApprenant';
import AddStudent from './AddStudent'; // Nouveau composant
import Etudiant from './Etudiant';
import GlobalGauge from '../StudentDashboard/GlobalGauge/GlobalGauge';
import BaddestGauge from '../StudentDashboard/BaddestGauge/BaddestGauge';

function AdminFirstModule({ classe, filteredUsers }) {
  const [open, setOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState({});
  const [view, setView] = useState(true); // true = "ListApprenant", false = "AddStudent"

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleView = () => {
    setView((prevView) => !prevView); // Bascule entre true et false
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3} className="row1">

        {/* Affichage conditionnel */}
        {view ? (
          <ListApprenant
            classe={classe}
            open={open}
            setOpen={setOpen}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            setSelectedStudent={setSelectedStudent}
            toggleView={toggleView}
            view={view}
          />
        ) : (
          <Grid   lg={8} sx={{pt:'24px', pl:'24px'}}>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 520
                }}
            >
              <AddStudent 
              classe={classe}
              toggleView={toggleView}
              view={view}
              filteredUsers={filteredUsers}

              />
            </Paper>
          </Grid>
        )}

        {/* Composant Etudiant */}
        <Etudiant
          classe={classe}
          open={open}
          setOpen={setOpen}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          selectedStudent={selectedStudent}
        />

        {/* Gauges */}
        <Grid
          container
          spacing={3}
          lg={4}
          sx={{
            p: 3,
            pb: 0,
            paddingTop: '48px',
            display: 'flex',
            width: 'fit-content',
            height: '568px',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '48%',
              justifyContent: 'space-between',
            }}
          >
            <GlobalGauge classe={classe} />
          </Paper>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '48%',
              justifyContent: 'space-between',
            }}
          >
            <BaddestGauge classe={classe} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AdminFirstModule;
