import React, { useState, useEffect } from 'react';
import SignIn from '../Composants/StudentDashboard/SignIn';
import Dashboard from '../Composants/StudentDashboard/Dashboard';
import AdminDashboard from '../Composants/AdminDashboard/AdminDashboard';
import { AuthProvider, useAuth } from '../UserContext';
import CircularProgress from '@mui/material/CircularProgress'; // Importer le composant de chargement
import Box from '@mui/material/Box';
import axios from 'axios'

function Accueil() {
    const [loading, setLoading] = useState(true); // État pour le chargement
    const [isLoggedIn, setIsloggedIn] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [admin, setAdmin] = useState([]);
    const [userID, setUserID] = useState('');
    const { currentUser, logout } = useAuth();

        // Fonction pour vérifier l'état d'authentification
        const checkAuth = async () => {
          try {
              // Ici, vous pouvez vérifier l'état d'authentification et ajuster les états en conséquence
              if (currentUser) {
                  setIsloggedIn(true); // Utilisateur est authentifié
                  console.log('Token Firebase :', await currentUser.getIdToken());

              } else {

                  setIsloggedIn(false); // Utilisateur n'est pas authentifié
              }
              const response = await axios.get('https://backend-codeschool241.onrender.com/admins');

              for (let index = 0; index < response.data.length; index++) {
                const element = response.data[index];

                
                
                if (currentUser.reloadUserInfo.localId === element.id) {
                  setIsAdmin(true)
                  setAdmin(currentUser.reloadUserInfo);
                }
              }
              setUserID(currentUser.reloadUserInfo.localId);  

              setTimeout(() => {
                setLoading(false); // Mettre à jour le statut de chargement une fois vérifié
                
                
              }, 3000);
          } catch (error) {
            setTimeout(() => {
              setLoading(false); // Mettre à jour le statut de chargement une fois vérifié
              
              
            }, 5000);
              console.error('Erreur lors de la vérification de l\'authentification', error);
          }
      };

    // Effet pour gérer le chargement de l'authentification
    useEffect(() => {


        checkAuth();
    }, [currentUser]); // Dépendance à currentUser pour re-vérifier en cas de changement



    return (
        <AuthProvider>
          {loading ? (
            <Box sx={{ display: 'flex',flexDirection:'column', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor:'#212121' }}>
              <img src="/img/64px.png" alt="" style={{heigth:'64px', position:'relative'}}/>
              <CircularProgress style={{position:"absolute", bottom:'10%'}}/>
            </Box>
          ) : (
            <div className="main-accueil">
              {currentUser ? (
                <div>
                  {isAdmin ? (
                  <AdminDashboard logout={logout} currentUser={currentUser} setIsAdmin={setIsAdmin} admin={admin} />
                ) : (
                  <Dashboard logout={logout} currentUser={currentUser} />
                )}
                </div>
                
              ) : (
                  <SignIn isLoggedIn={isLoggedIn} setIsloggedIn={setIsloggedIn} setUserID={setUserID} />
              )}
            </div>
          )}
            
        </AuthProvider>
    );
}

export default Accueil;
