import React, { useState } from 'react';import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ProgressBar from './ProgressBar';
import Divider from '@mui/material/Divider';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Gauge } from '@mui/x-charts/Gauge';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import { Stepper, Step, StepLabel, StepContent } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Grid} from '@mui/material';
import {

  List,
  ListItem,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { CircularProgress, Snackbar, Alert } from "@mui/material";


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function AccordionTracker({ filteredUsers, addObjectif }) {

  
  const [activeStep, setActiveStep] = React.useState(0);

  const [expanded, setExpanded] = React.useState('panel1');
  const [array, setarray] = React.useState([]);
  const [objectifs, setobjectifs] = React.useState([]);
  const [briefs, setbriefs] = React.useState([]);
  const [newModule, setNewModule] = React.useState({
    module: '',
    descriprion: '',
    debut: '',
    fin: '',
    competences: '',
    cohorte: filteredUsers[0].referentiel
  });

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleClick = (module) => {
    console.log(module.id_module); // Affiche l'élément cliqué dans la console
  };

  React.useEffect(() => {
    const referentiel = filteredUsers[0].referentiel === "Développeur web" ? "dev" : "ref";
    const promo = filteredUsers[0].promo;
    const checkAuth = async () => {
      const response = await axios.get('https://backend-codeschool241.onrender.com/modules');
      setarray(response.data);
      const response2 = await axios.get('https://backend-codeschool241.onrender.com/objectifs');
      setobjectifs(response2.data);
      const response3 = await axios.get(`https://backend-codeschool241.onrender.com/allbriefs/${referentiel}/${promo}`);
      setbriefs(response3.data);
      console.log(response3.data);
    };
    checkAuth();
  }, []);

  const modules = array.filter(module => module.cohorte === filteredUsers[0].referentiel);

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split("-");
    return new Date(year, month - 1, day);
  };
  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split('-');
    return `${day}-${month}-${year}`;
  };
  

  const handleInputChange = (field, value) => {
    setNewModule((prev) => ({ ...prev, [field]: value }));
  };


  const competencesList = [
      "Gestion de Projet",
      "UI/UX Design",
      "Développement Front-end",
      "Marketing de Contenu",
      "Référencement SEO/SEA",
      "Gestion des Réseaux Sociaux",
      "Campagnes Publicitaires en Ligne",
      "E-mail Marketing",
      "Power BI",
      "Protection des Données",
      "Programmation JavaScript",
      "Développement Backend",
      "Gestion des Bases de Données",
      "API REST",
      "Gestion des versions (Git, GitHub, GitLab)",
      "Hébergement Web et Serveurs (AWS, Azure, Firebase, Netlify, Vercel)",
      "Sécurité Web (OWASP, JWT, OAuth)",
      "Agile et Scrum",
      "Design Thinking",
      "Conception Prototypage (Figma, Sketch, Adobe XD)",
      "Wireframing et Storyboarding",
      "Animation et Motion Design (After Effects, Lottie)",
      "Analyse de Données (Excel avancé, Tableau, Power BI)",
      "Marketing Automation (HubSpot, Mailchimp)",
      "Analyse de Trafic (Google Analytics, Matomo)",
      "Optimisation CRO (Conversion Rate Optimization)",
      "Veille Concurrentielle et Benchmarking",
    ]
  
  
  const [newObjectif, setNewObjectif] = useState('');
  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);        
  const toggleCompetence = (competence) => {
    setNewModule((prev) => {
      const competences = prev.competences.includes(competence)
        ? prev.competences.filter((c) => c !== competence) // Si déjà sélectionnée, on la retire
        : [...prev.competences, competence]; // Sinon on l'ajoute
  
      return { ...prev, competences }; // Mettre à jour l'état avec les compétences sélectionnées
    });
  };
  const handleAddModule = async () => {
    const formattedModule = {
      ...newModule,
      debut: formatDate(newModule.debut),
      fin: formatDate(newModule.fin),
      competences: newModule.competences, // Convertir en tableau stringifié
    };
  
    try {
      // Poster le nouveau module à ton API
      await axios.post('https://backend-codeschool241.onrender.com/modules', formattedModule);
      // setarray((prev) => [...prev, formattedModule]);
      // setNewModule({ module: '', descriprion: '', debut: '', fin: '', competences: '', cohorte: filteredUsers[0].referentiel }); // Réinitialiser l'état
      console.log(formattedModule);
      
    } catch (error) {
      console.error('Erreur lors de l’ajout du module:', error);
    } finally{
      window.location.reload()
    }
  };

  const [showForm, setShowForm] = useState(false);
  const [objectifToSet, setObjectifToSet] = useState({});
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ open: false, type: "", message: "" });


  const handleAddObjectif = async (module) => {
    setLoading(true); // Démarre le chargement

    try {
      setObjectifToSet();

      
    } catch (error) {
      console.error('Erreur lors de l’ajout de l\'objectif:', error);
    } finally{
      // window.location.reload()
      try {
        // Poster le nouveau module à ton API
        await axios.post('https://backend-codeschool241.onrender.com/objectifs', { objectif: newObjectif, id_module: module.id_module});
        console.log({ objectif: newObjectif, id_module: module.id_module});
        
        // setarray((prev) => [...prev, formattedModule]);
        // setNewModule({ module: '', descriprion: '', debut: '', fin: '', competences: '', cohorte: filteredUsers[0].referentiel }); // Réinitialiser l'état

      // Si succès
      setNotification({
        open: true,
        type: "success",
        message: "Objectif ajouté avec succès !",})
        
      } catch (error) {
        // Si une erreur se produit
      setNotification({
        open: true,
        type: "error",
        message: "Erreur lors de l'ajout de l'objectif.",
      });
        console.error('Erreur lors de l’ajout de l\'objectif:', error);
      } finally{
        await new Promise((resolve) => setTimeout(resolve, 3000)); // Exemple d'attente

        setLoading(false); // Arrête le chargement
        window.location.reload()
        setNewObjectif('');
        setShowForm(false);
      }
    }
    if (newObjectif.trim() === '') return;

    // Appeler la fonction pour ajouter un nouvel objectif

  };
  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };


  return (
    <div>
      {modules.map((module) => {
        const objectifsfiltrés = objectifs.filter(objectif => objectif.id_module === module.id_module);

        const start = parseDate(module.debut);
        const end = parseDate(module.fin);
        const diffInMs = end - start;
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

        let totalValeur = 0;
        objectifsfiltrés.forEach((objectif) => {
          const filteredbriefs = briefs.filter(module => module.id_objectif === objectif.id_objectif);
          const uniqueBriefs = [];
          const studentIds = new Set();

          filteredbriefs.forEach(brief => {
            if (!studentIds.has(brief.id_etudiant)) {
              studentIds.add(brief.id_etudiant);
              uniqueBriefs.push(brief);
            }
          });

          const briefWithStatus1 = filteredbriefs.filter(module => module.statut === 2);
          const valeur = Math.floor(briefWithStatus1.length / uniqueBriefs.length * 100);
          totalValeur += valeur;
        });

        const moyenneValeur = objectifsfiltrés.length > 0 ? Math.floor(totalValeur / objectifsfiltrés.length) : 0;
    return (
          <Accordion key={module.id_module} expanded={expanded === `panel${module.id_module}`} onChange={handleChange(`panel${module.id_module}`)}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography>{module.module}</Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ margin: '0 5px 0px 20px' }}>{isNaN(moyenneValeur) ? 0 : moyenneValeur}%</span>
                  <div style={{ width: '200px', }}>
                    <ProgressBar value={moyenneValeur} />
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontWeight: 'bold' }}>Description:</Typography>
              <Typography>{module.descriprion}</Typography>
              <br />
              <Typography sx={{ fontWeight: 'bold' }}>Durée:</Typography>
              <Typography>{diffInDays} jours (Du {module.debut} au {module.fin})</Typography>
              <br />
              <Typography sx={{ fontWeight: 'bold' }}>Objectifs:</Typography>
      <div style={{ width: '100%' }}>
        {objectifsfiltrés.map((objectif) => {
          const filteredbriefs = briefs.filter(module => module.id_objectif === objectif.id_objectif);
          const uniqueBriefs = [];
          const studentIds = new Set();

          filteredbriefs.forEach(brief => {
            if (!studentIds.has(brief.id_etudiant)) {
              studentIds.add(brief.id_etudiant);
              uniqueBriefs.push(brief);
            }
          });

          const briefWithStatus1 = filteredbriefs.filter(module => module.statut === 2);
          const valeur = isNaN(Math.floor((briefWithStatus1.length / uniqueBriefs.length) * 100))
          ? 0
          : Math.floor((briefWithStatus1.length / uniqueBriefs.length) * 100);
                  
          return (
            <div key={objectif.id_objectif}>
              <div className='main-progress-box'>
                <div style={{ display: 'flex', alignContent: 'center', margin: '10px 0' }}>
                  <CheckCircleOutlineIcon />
                  <span style={{ marginLeft: '15px' }}>{objectif.objectif}</span>
                </div>
                <div style={{ height: '50px', width: '50px' }}>
                  <Gauge
                    width={100}
                    height={100}
                    value={valeur}
                    style={{ height: '100%', width: '100%' }}
                    className='GlobalGauge'
                  />
                </div>
              </div>
              <Divider />
            </div>
          );
        })}

            <List sx={{ padding: '0' }}>

                  <ListItem disablePadding>
                    <ListItemButton
                      sx={{
                        color: 'white',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                        padding: '5px 0px',
                      }}
                      onClick={() => {setShowForm(!showForm);handleClick(module)}}
                    >
                      <div>
                        <div className='main-progress-box' style={{color: '#ED462F' }}>
                          <div style={{ display: 'flex', alignContent: 'center', margin: '10px 0' }}>
                            <AddCircleOutlineIcon />

                            <Typography sx={{ marginLeft:'15px'}}>
                              {showForm ? 'Annuler' : 'Ajouter un objectif'}
                            </Typography>
                          </div>

                        </div>
                      </div>
                    </ListItemButton>

                  </ListItem>

              </List>

        

        {/* Formulaire pour ajouter un nouvel objectif */}
        {showForm && (
         <div style={{ marginTop: "15px" }}>
         <TextField
           label="Nouvel Objectif"
           value={newObjectif}
           onChange={(e) => setNewObjectif(e.target.value)}
           fullWidth
           variant="outlined"
           style={{ marginBottom: "10px" }}
         />
         <Button
           variant="contained"
           color="primary"
           onClick={() => handleAddObjectif(module)}
           disabled={loading} // Désactive le bouton pendant le chargement
           style={{ position: "relative" }}
         >
           {loading ? (
             <CircularProgress size={24} style={{ color: "white" }} /> // Indicateur de chargement
           ) : (
             "Ajouter"
           )}
         </Button>
         {/* Notification */}
         <Snackbar
           open={notification.open}
           autoHideDuration={4000}
           onClose={handleCloseNotification}
           anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
         >
           <Alert onClose={handleCloseNotification} severity={notification.type} variant="filled">
             {notification.message}
           </Alert>
         </Snackbar>
       </div>
        )}
      </div>
              <br />
              <Typography sx={{ fontWeight: 'bold' }}>Compétence(s) abordée(s):</Typography>
              {Array.isArray(module.competences) &&
              module.competences.map((competence, index) => (
                <span key={index}>
                  {competence}
                  {index === module.competences.length - 1 ? "." : ", "}
                </span>
              ))}

            </AccordionDetails>
          </Accordion>
        );
      })}
      <br />
      <Accordion 
        expanded={expanded === 'addModule'} 
        onChange={handleChange('addModule')} 
        sx={{
          backgroundColor: '#1E1E1E', // Couleur de fond différente
          border: '2px solid #1E1E1E', // Bordure spécifique
          '& .MuiAccordionSummary-content': {
            justifyContent: 'center', // Centrer le titre
            textAlign: 'center', // Centrer le texte
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            justifyContent: 'center', // Centrer l'icône
          },
        }}
      >
        <AccordionSummary>
          <Typography sx={{ fontWeight: 'bold', color: '#ED462F' }}>Ajouter un nouveau module</Typography>
        </AccordionSummary>
        <AccordionDetails>


        {/* Formulaire d'ajout de module */}
        <div style={{ marginTop: '20px' }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {/* Étape 1 */}
          <Step>
            <StepLabel>Informations générales</StepLabel>
            <StepContent>
              <TextField
                label="Nom du module"
                fullWidth
                margin="normal"
                value={newModule.module}
                onChange={(e) => handleInputChange('module', e.target.value)}
              />
              <TextField
                label="Description"
                fullWidth
                margin="normal"
                value={newModule.descriprion}
                onChange={(e) => handleInputChange('descriprion', e.target.value)}
              />
              <TextField
                label="Date de début"
                type="date"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={newModule.debut}
                onChange={(e) => handleInputChange('debut', e.target.value)}
              />
              <TextField
                label="Date de fin"
                type="date"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={newModule.fin}
                onChange={(e) => handleInputChange('fin', e.target.value)}
              />
              <div style={{ marginTop: '20px' }}>
                <Button variant="contained" onClick={handleNext}>
                  Suivant
                </Button>
              </div>
            </StepContent>
          </Step>

          {/* Étape 2 */}
          <Step>
            <StepLabel>Choix des compétences abordées</StepLabel>
            <StepContent>
            <div>
              <Grid container spacing={2}>
                {competencesList.map((competence, index) => (
                  <Grid item xs={4} key={index}> {/* 4 éléments pour 3 colonnes */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newModule.competences.includes(competence)}
                          onChange={() => toggleCompetence(competence)}
                        />
                      }
                      label={competence}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>

              <div style={{ marginTop: '20px' }}>
                <Button onClick={handleBack} style={{ marginRight: '10px' }}>
                  Retour
                </Button>
                <Button variant="contained" onClick={handleNext}>
                  Suivant
                </Button>
              </div>
            </StepContent>
          </Step>

          {/* Étape 3 */}
          <Step>
            <StepLabel>Résumé</StepLabel>
            <StepContent>
              <Typography>
                <strong>Nom du module :</strong> {newModule.module}
              </Typography>
              <br />
              <Typography>
                <strong>Description :</strong> {newModule.descriprion}
              </Typography>
              <br />
              <Typography>
                <strong>Dates :</strong> {formatDate(newModule.debut)} - {formatDate(newModule.fin)}
              </Typography>
              <br />
              <Typography>
                <strong>Compétences abordées :</strong>{' '}
                {newModule.competences.length > 0
                  ? newModule.competences.join(', ')
                  : 'Aucune compétence sélectionnée.'}
              </Typography>
              <Divider style={{ margin: '20px 0' }} />
              <div>
                <Button onClick={handleBack} style={{ marginRight: '10px' }}>
                  Retour
                </Button>
                <Button variant="contained" color="primary" onClick={handleAddModule}>
                  Valider
                </Button>
              </div>
            </StepContent>
          </Step>
        </Stepper>
      </div>

        </AccordionDetails>
      </Accordion>

    </div>
  );
}
