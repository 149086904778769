import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DownloadIcon from '@mui/icons-material/Download';
import TextField from '@mui/material/TextField';
import LinkIcon from '@mui/icons-material/Link';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import InputAdornment from '@mui/material/InputAdornment';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';
import '../../App.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Brief({ handleClose, open, competence, filteredUsers }) {
  const [message, setMessage] = React.useState('');
  const [admins, setAdmins] = React.useState([]);
  const [link, setLink] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  // Filtrer les compétences pour exclure celle du competence.id
  const objetsFiltres = (filteredUsers && filteredUsers[0].briefs) 
    ? filteredUsers[0].briefs.filter(objet => objet.id !== competence.id) 
    : [];

  const brief = {
    titre: competence.titre,
    date: competence.date,
    source: competence.source,
    statut: 1,
    description: competence.description
  };

  useEffect(() => {
    // Fonction pour vérifier l'état d'authentification
    checkAdmin()
  })  
  const checkAdmin= async () => {

    const response = await axios.get('https://backend-codeschool241.onrender.com/admins');
    setAdmins(response.data)
    console.log(response.data)

  }

  const handleSend = async () => {
    if (!link) {
      alert('Veuillez entrer un lien avant d\'envoyer.');
      return;
    }



    if (!filteredUsers || filteredUsers.length === 0) {
      alert('Aucun utilisateur trouvé pour envoyer le message.');
      return;
    }

    const referentiel = filteredUsers[0]?.referentiel;
    const promo = filteredUsers[0]?.promo;

    let recipient;

    if (referentiel === 'Référent digital') {
      recipient = 'adirignoogoula@gmail.com';
    } else if (referentiel === 'Développeur web') {
      recipient = 'yohanndian@gmail.com';
    } else {
      alert('Référentiel non valide.');
      return;
    }

    setIsLoading(true);

    try {
      // Envoyer l'e-mail
      await axios.post('https://backend-codeschool241.onrender.com/send-email', {
        to: recipient,
        subject: `${filteredUsers[0].prenom} ${filteredUsers[0].nom} - Réponse à l'évaluation: ${competence.titre}`,
        text: message,
        link: link,
        competence: competence,
      });

      // Mettre à jour l'état des briefs
      objetsFiltres.push(brief);
      await axios.put(`https://backend-codeschool241.onrender.com/updatestatus/${filteredUsers[0].id}`, { brief: JSON.stringify(objetsFiltres) });

      alert('Message envoyé avec succès.');
      handleClose();
    } catch (error) {
      console.error('Erreur lors de l\'envoi du message:', error);
      alert('Message envoyé avec succès.');
    } finally {
      setIsLoading(false);
      window.location.reload();  // Recharger la page après succès

    }
  };

  const lien = `${competence.source}`;

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {competence.titre}
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ padding: "0 63px", display: 'flex' }} className='layout-brief'>
          <div className='brief-description'>
            <h3>Description:</h3>
            <p>{competence.description}</p>
            <br />
            <h3>Pièce jointe:</h3>
            <Button variant="outlined" startIcon={<DownloadIcon />} href={lien} download={competence.source}>
              Télécharger le fichier
            </Button>
            <br />
            <h3>Date de livraison:</h3>
            <p>{competence.date}</p>
            <br />
          </div>
          {competence.statut === 0 && (
            <div className='response brief-description' style={{ marginTop: '35px' }}>
              <TextField
                id="outlined-multiline-static"
                label="Message"
                multiline
                rows={7}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                sx={{ width: '100%' }}
              />
              <TextField
                id="input-with-icon-textfield"
                label="Lien"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                sx={{ margin: '30px 0', width: '100%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              <Button 
                variant="contained" 
                endIcon={isLoading ? <CircularProgress size={20} /> : <SendIcon />} 
                sx={{ width: '100%' }} 
                onClick={handleSend}
                disabled={isLoading || !link}
              >
                {isLoading ? 'Envoi en cours...' : 'Envoyer'}
              </Button>
            </div>
          )}
        </div>
      </Dialog>
    </React.Fragment>
  );
}
