import React from 'react';
import Title from './Title';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import '../../App.css';
import ClassIcon from '@mui/icons-material/Class';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import Brief from './Brief';
import Activite from './Activite';

function Briefs({briefs, setIsAnActivity,isAnActivity,filteredUsers, handleClickOpen,selectedCompetence, ouvrir, handleClose  }) {
    if (!filteredUsers || filteredUsers.length === 0 || !Array.isArray(filteredUsers[0].briefs)) {
        // Gestion du cas où les données ne sont pas disponibles ou incorrectes
        return (
            <div>
                <span style={{fontSize:'20px', fontWeight:'bold', textAlign:'center', color:'#ED462F', lineHeight:'14px'}}>Briefs d'évaluation</span>
                <div>Aucun brief d'évaluation disponible.</div>
            </div>
        );
    }
    const filtered = briefs.filter(brief => brief.id_etudiant === filteredUsers[0].id);
    
    // Filtrer les éléments ayant statut === 0
    const briefsWithStatutZero = filteredUsers[0].briefs
        .filter(competence => competence.statut === 0)
        // .slice(-3); // Obtenir les 3 derniers éléments filtrés
    // Filtrer les éléments ayant statut === 0
    const activitiesWithStatutZero = filtered
        .filter(competence => competence.statut === 0)
      // Fonction pour mélanger deux tableaux
    const combinedArray = briefsWithStatutZero.concat(activitiesWithStatutZero);
    const array = combinedArray.slice(-3); // Obtenir les 3 derniers éléments filtrés
    // eslint-disable-next-line react-hooks/rules-of-hooks

     // Fonction pour convertir une date au format jj-mm-aaaa en objet Date
    function parseDate(dateStr) {
        const [day, month, year] = dateStr.split('-').map(Number);
        return new Date(year, month - 1, day);
    }
    const arraybriefs = array.sort((a, b) => parseDate(b.date) + parseDate(a.date));
    
    return (
        <div>
            <span style={{fontSize:'20px', fontWeight:'bold', textAlign:'center', color:'#ED462F', lineHeight:'14px'}}>Briefs d'évaluation</span>
            {array.length === 0 ? (
                <div>Aucun brief d'évaluation à rendre.</div>
            ) : (
                <List>
                    {arraybriefs.reverse().map((competence, index) => (
                        <ListItemButton 
                        className='box-brief'
                        onClick={() => {handleClickOpen(competence); setIsAnActivity(competence)}}
                        sx={{marginLeft:0}}
                        >
                           <div style={{ display: 'flex', width:'100%' }}>
                            <ListItemIcon sx={{ minWidth: 'fit-content', marginRight: '10px' }}>
                                <ClassIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ color: 'white' }}>
                                {competence.titre.length > 25 
                                ? `${competence.titre.slice(0, 25)}...` 
                                : competence.titre}
                            </ListItemText>
                            </div>
                            <div className='datebrief'>
                                <ListItemIcon sx={{ minWidth: 'fit-content', marginRight: '10px' }}>
                                    <AccessAlarmIcon />
                                </ListItemIcon>
                                <ListItemText sx={{ color: 'white', textAlign: 'right' }}>
                                    {competence.date || 'Date non définie'}
                                </ListItemText>
                            </div>
                        </ListItemButton>
                    ))}
                </List>
            )}
            {selectedCompetence && (
            <div>

                {!isAnActivity.id_etudiant ? (

                <Brief 
                    open={ouvrir} 
                    handleClickOpen={handleClickOpen} 
                    handleClose={handleClose} 
                    competence={selectedCompetence} 
                    filteredUsers={filteredUsers}
                />
            ) : (      
                <Activite 
                    open={ouvrir} 
                    handleClickOpen={handleClickOpen} 
                    handleClose={handleClose} 
                    competence={selectedCompetence} 
                    filteredUsers={filteredUsers}
                />
          )}
            </div>



            )}
        </div>
    );
}

export default Briefs;
