import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  IconButton,
} from '@mui/material';
import axios from 'axios';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import Activite from './Activite';
import "../../App.css";

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

// Fonction principale
export default function AccordionTracker({ filteredUsers }) {
  const [expanded, setExpanded] = useState(null);
  const [modules, setModules] = useState([]);
  const [objectifs, setObjectifs] = useState([]);
  const [briefs, setBriefs] = useState([]);
  const [expandedDescription, setExpandedDescription] = useState({});
  const [selectedCompetence, setSelectedCompetence] = useState(null);
  const [open, setOpen] = useState(false);

  // Charger les données des modules, objectifs et briefs depuis le backend
  useEffect(() => {
    const fetchModulesAndObjectifs = async () => {
      try {
        const [modulesResponse, objectifsResponse, briefsResponse] = await Promise.all([
          axios.get('https://backend-codeschool241.onrender.com/modules'),
          axios.get('https://backend-codeschool241.onrender.com/objectifs'),
          axios.get('https://backend-codeschool241.onrender.com/briefs'),
        ]);

        setModules(modulesResponse.data);
        setObjectifs(objectifsResponse.data);
        setBriefs(briefsResponse.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };

    fetchModulesAndObjectifs();
  }, []);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const handleDescriptionToggle = (moduleId) => {
    setExpandedDescription((prev) => ({
      ...prev,
      [moduleId]: !prev[moduleId], // Alterner l'état d'expansion de la description
    }));
  };

  // Filtrer les briefs correspondant à l'étudiant sélectionné
  const filteredBriefs = briefs.filter(
    (brief) => brief.id_etudiant === filteredUsers[0]?.id
  );

  // Trouver les objectifs associés aux briefs filtrés
  const filteredObjectifs = objectifs.filter((objectif) =>
    filteredBriefs.some((brief) => brief.id_objectif === objectif.id_objectif)
  );

  // Filtrer les modules en fonction des objectifs filtrés
  const filteredModules = modules.filter((module) =>
    filteredObjectifs.some((objectif) => objectif.id_module === module.id_module)
  );

  // Gérer l'ouverture du modal Activité
  const handleObjectifClick = (objectifId) => {
    console.log(`Objectif sélectionné : ${objectifId}`);

    // Filtrer les briefs pour trouver ceux qui correspondent à l'étudiant et à l'objectif cliqué
    const briefCorrespondant = filteredBriefs.find(
      (brief) => brief.id_etudiant === filteredUsers[0]?.id && brief.id_objectif === objectifId
    );

    if (briefCorrespondant) {
      console.log('Brief correspondant:', briefCorrespondant);
      setSelectedCompetence(briefCorrespondant);  // Passer le brief dans l'état
      setOpen(true);  // Ouvrir le modal
    } else {
      console.log('Aucun brief trouvé pour cet objectif et cet étudiant');
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCompetence(null);
  };

  return (
    <div style={{ marginTop: '15px' }}>

      {filteredModules.map((module) => {
        const objectifsForModule = filteredObjectifs.filter(
          (objectif) => objectif.id_module === module.id_module
        );

        const shortDescription = module.descriprion.slice(0, 200);
        const isDescriptionExpanded = expandedDescription[module.id_module];

        return (
          <Accordion
            key={module.id_module}
            expanded={expanded === `panel${module.id_module}`}
            onChange={handleAccordionChange(`panel${module.id_module}`)}
          >
            <AccordionSummary
              aria-controls={`panel${module.id_module}-content`}
              id={`panel${module.id_module}-header`}
            >
              <Typography>{module.module}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontWeight: 'bold', margin: '15px 0' }}>Description:</Typography>
              <Typography>
                {isDescriptionExpanded ? module.descriprion : shortDescription}
                {module.descriprion.length > 100 && (
                  <IconButton
                    onClick={() => handleDescriptionToggle(module.id_module)}
                    sx={{ color: 'red', fontSize: '12px', marginLeft: 1 }}
                  >
                    {isDescriptionExpanded ? 'Moins' : 'Plus'}
                  </IconButton>
                )}
              </Typography>
              

              <Typography sx={{ fontWeight: 'bold', marginTop: 2 }}>Objectifs:</Typography>
              <List sx={{ padding: '0' }}>
                {objectifsForModule.map((objectif) => (
                  <ListItem key={objectif.id_objectif} disablePadding>
                    <ListItemButton
                      onClick={() => handleObjectifClick(objectif.id_objectif)}
                      sx={{
                        color: 'white',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                        padding: '5px 0px',
                      }}
                    >
                      <HistoryEduIcon sx={{ marginRight: 1 }} />
                      <ListItemText
                        primary={
                          <Typography className="objectif-title" variant="body1">
                            {objectif.objectif}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>

              <Typography sx={{ fontWeight: 'bold', margin: '15px 0' }}>
                Compétence(s) abordée(s):
              </Typography>
              {module.competences.map((competence, index) => (
                <span key={index}>
                  {competence}
                  {index === module.competences.length - 1 ? '.' : ', '}
                </span>
              ))}
            </AccordionDetails>
          </Accordion>
        );
      })}

      {/* Si une compétence est sélectionnée, afficher le modal Activité */}
      {selectedCompetence && (
        <Activite 
          open={open} 
          handleClose={handleClose} 
          competence={selectedCompetence} 
          filteredUsers={filteredUsers}
        />
      )}
    </div>
  );
}
