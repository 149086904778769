import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Divider from '@mui/material/Divider';
import DeleteBrief from './DeleteBrief';
import axios from 'axios';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function Activity({
  allResponses,
  briefs,
  isOpen,
  setIsOpen,
  setIsLoading,
  relatedBriefs,
  allBriefs,
  setMessage,
  brief,
  parseDate,
  today,
  number,
  classe,
}) {
  const [open, setOpen] = React.useState(false);
  const [isResponseDialogOpen, setIsResponseDialogOpen] = React.useState(false); // État pour le second dialogue
  const [IdBrief, setIdBrief] = React.useState({});
  const [reponses, setReponses] = React.useState([]);
  const [selectedReponse, setSelectedReponse] = React.useState(null);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setIsOpen(true);
    setOpen(false);
    setSelectedReponse(null);
  };

  const handleResponseDialogOpen = (reponse) => {
    console.log("ID du brief associé :", reponse.id_brief); // Affiche id_brief en console
    setSelectedReponse(reponse);
    setIdBrief(reponse.id_brief)
    setIsResponseDialogOpen(true);
    console.log(allBriefs);
    
  };
  

  const handleResponseDialogClose = () => {
    setIsResponseDialogOpen(false);
    setSelectedReponse(null);
  };

  const handleDelete = async () => {
    if (!brief.titre) {
      alert('Veuillez entrer un titre pour supprimer le brief.');
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.delete('https://backend-codeschool241.onrender.com/briefs', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          titre: brief.titre,
        },
      });

      setMessage(`Brief supprimé avec succès. Nombre de briefs affectés : ${response.data.affectedRows}`);
    } catch (error) {
      console.error('Erreur lors de la suppression du brief :', error);
      setMessage('Erreur lors de la suppression du brief.');
    } finally {
      setIsLoading(false);
      window.location.reload();
    }
  };

  const handleFilterResponses = () => {
    if (!Array.isArray(allResponses) || !Array.isArray(relatedBriefs)) {
      console.error('Données invalides : allResponses ou relatedBriefs ne sont pas des tableaux.');
      return;
    }

    const filteredResponsesByBrief = allResponses.filter((response) =>
      relatedBriefs.some((brief) => brief.id === response.id_brief)
    );
    console.log(filteredResponsesByBrief)

    setReponses(filteredResponsesByBrief);
  };

  const handleValidateBrief = async (id, statut) => {

    setIsLoading(true);
    console.log(id);
    

    try {
      const response = await axios.put(
        `https://backend-codeschool241.onrender.com/brief/${IdBrief}`,
        { statut },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.status === 200) {
        setMessage('Le statut du brief a été mis à jour avec succès.');
        window.location.reload(); // Rechargez la page pour actualiser les données
      } else {
        setMessage('Erreur lors de la mise à jour du statut du brief.');
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour du statut du brief:', error);
      setMessage('Erreur lors de la mise à jour du statut.');
    } finally {
      setIsLoading(false);
      // window.location.reload();
    }
  };
  

  return (
    <React.Fragment>
      <ListItemButton
        onClick={() => {
          handleClickOpen();
          handleFilterResponses();
        }}
      >
        <div className="row1">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', padding: '15px 0', alignItems: 'center' }}>
              <div style={{ border: '1px solid transparent' }} className="marge">
                <span style={{ fontSize: '18px', color: 'white', fontWeight: '600', whiteSpace: 'nowrap' }}>
                  {brief.titre}
                </span>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', width: '185px', justifyContent: 'space-between', height: 'fit-content' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className="circleiconinfos">
                <PersonOutlineOutlinedIcon sx={{ height: '15px', color: '#1E1E1E' }} />
              </div>
              <span style={{ fontSize: '20px', color: 'white', width: '30px' }}>
                {(number && number.length) || 0}
              </span>
            </div>
            <Divider orientation="vertical" variant="middle" flexItem />
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
              <div className="circleiconinfos">
                <AccessAlarmsIcon sx={{ height: '15px', color: '#1E1E1E' }} />
              </div>
              <span style={{ fontSize: '20px', color: 'white', width: '110px' }}>
                {parseDate(brief.date) < today ? 'Terminé' : brief.date}
              </span>
            </div>
          </div>
        </div>
      </ListItemButton>

      {/* Dialogue principal pour les détails du Brief */}
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {brief.titre}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>

        {isOpen ? (
          <>
            <DialogContent dividers>
              <span style={{ fontWeight: 'bold' }}>Description :</span>
              <p>{brief.description || 'Message non disponible'}</p>
              <br />
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '95%' }}>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Date de livraison :</span>
                  <br />
                  <p>{brief.date}</p>
                </div>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Pièce jointe :</span>
                  <br />
                  <Button variant="outlined" startIcon={<DownloadIcon />} href={brief.source} download={brief.source}>
                    Télécharger le fichier
                  </Button>
                </div>
              </div>
              <br />
              <span style={{ fontWeight: 'bold' }}>Apprenants :</span>
              <br />
              <div style={{ display: 'flex', flexWrap: 'wrap', paddingBottom: '50px', marginTop: '7px' }}>
                {number.map((label) => {
                  const etudiant = classe.find((item) => item.id === label.id_etudiant);
                  if (!etudiant) {
                    console.error(`Aucun étudiant trouvé pour l'id : ${label.id_etudiant}`);
                    return null;
                  }

                  return (
                    <div
                      key={label.id_etudiant}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px 20px 10px 10px',
                        backgroundColor: '#121212',
                        borderRadius: '50px',
                        width: 'fit-content',
                        marginRight: '15px',
                        marginBottom: '10px',
                        border: '1px solid rgb(48 48 48)',
                      }}
                    >
                      <Avatar alt={`${etudiant.prenom}`} src={`${etudiant.photo}`} sx={{ marginRight: '15px' }} />
                      {etudiant.prenom}
                    </div>
                  );
                })}
              </div>
              <span style={{ fontWeight: 'bold' }}>Réponses :</span>
              <br />
              <div>
              {[...reponses]
              .reverse() // Inverser pour parcourir les réponses de la plus récente à la plus ancienne
              .reduce((uniqueResponses, currentReponse) => {
                const isDuplicate = uniqueResponses.some(
                  (reponse) =>
                    reponse.id_brief === currentReponse.id_brief &&
                    reponse.id_etudiant === currentReponse.id_etudiant
                );

                if (!isDuplicate) {
                  uniqueResponses.push(currentReponse);
                }

                return uniqueResponses;
              }, [])
              .reverse() // Réinverser pour restaurer l'ordre d'origine
              .map((reponse, index) => {
                const etudiant = classe.find((module) => module.id === reponse.id_etudiant);
                const briefReponse = relatedBriefs.filter((module) => module.id === reponse.id_brief);
                const prenom = etudiant ? etudiant.prenom : 'Nom non disponible';

                const renderStatus = (statut) => {
                  if (statut === 1) {
                    return <span style={{ color: 'white' }}>En attente</span>;
                  } else if (statut === 2) {
                    return <span style={{ color: 'green' }}>Validé</span>;
                  } else if (statut === 3) {
                    return <span style={{ color: 'red' }}>Invalidé</span>;
                  } else {
                    return <span style={{ color: 'gray' }}>Statut inconnu</span>;
                  }
                };

                return (
                  <ListItemButton
                    key={index}
                    onClick={() => handleResponseDialogOpen(reponse)} // Ouvre le second Dialogue
                    sx={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <div style={{ padding: '4px 0', width: '100%' }}>
                      <div style={{ padding: '5px 5px', display: 'flex', justifyContent: 'space-between' }}>
                        <span>{prenom}</span>
                        <span>{briefReponse[0] ? renderStatus(briefReponse[0].statut) : 'Statut inconnu'}</span>
                      </div>
                      <Divider />
                    </div>
                  </ListItemButton>
                );
              })}

              </div>
            </DialogContent>
            <DialogActions>
              <DeleteBrief handleDelete={handleDelete} />
            </DialogActions>
          </>
        ) : (
          <p>Les données ne sont pas disponibles.</p>
        )}
      </BootstrapDialog>

      {/* Second Dialogue pour afficher les détails d'une Réponse */}
      <BootstrapDialog
        onClose={handleResponseDialogClose}
        aria-labelledby="response-dialog-title"
        open={isResponseDialogOpen}
      >
        <DialogTitle id="response-dialog-title" sx={{ minWidth: '40vw' }}>
          Détails de la réponse
        </DialogTitle>
        {selectedReponse && (
          <DialogContent dividers>
            {(() => {
              const matchingEtudiant = classe.find(
                (etudiant) => etudiant.id === selectedReponse.id_etudiant
              );

              return (
                <p>
                  <strong>Prénom :</strong>{' '}
                  {matchingEtudiant ? matchingEtudiant.prenom : 'Nom non disponible'}
                </p>
              );
            })()}
            <p><strong>Message :</strong> {selectedReponse.message}</p>
            <p>
              <strong>Lien :</strong>{' '}
              <a href={selectedReponse.lien} target="_blank" rel="noopener noreferrer">
                Voir le document
              </a>
            </p>
          </DialogContent>
        )}

        {/* Vérification du statut du brief lié à la réponse */}
        {selectedReponse && (() => {
          const relatedBrief = relatedBriefs.find(
            (brief) => brief.id === selectedReponse.id_brief
          );

          // Si le statut du brief est égal à 1, afficher les boutons
          return relatedBrief?.statut === 1 ? (
            <DialogActions>
              <Button
                onClick={() => handleValidateBrief(IdBrief, 3)}
                sx={{ color: 'red' }}
              >
                Invalider
              </Button>
              <Button
                onClick={() => handleValidateBrief(IdBrief, 2)}
                sx={{ color: 'white', backgroundColor: 'green' }}
              >
                Valider
              </Button>
            </DialogActions>
          ) : null;
        })()}
      </BootstrapDialog>


    </React.Fragment>
  );
}
